<template>
  <div>
    <app-main>
      <template v-slot:main-content>
        <!-- --------------------------step1 填写用户名，身份证号 testarea ---------------->
        <div class="qrcode-friends" :visible.sync="setUserinfoShow" id="setUserDiv">
          <div class="qrcode-title" style="border-bottom:1px solid #CCC;padding:0 0 10px 10px;">完善个人资料</div>
          <div class="qrcode-link">
            <div class="user-line">
              真 实 姓 名：<input name="true_name" id="true_name" placeholder="真实姓名"></input>
            </div>
            <div class="user-line">
              身份证号码：<input name="idnumber" id="idnumber" placeholder="身份证号码"></input>
            </div>
            <div class="user-line">
              <el-button @click="postSubmit()" type="primary" style="margin-left:106px;">提交</el-button>
            </div>
            <div class="user-line" style="font-size:14px;color:#666;">
              <span>声明：资料仅用于实名认证使用</span>
	      <span @click="guideDialogShow" class="msg">
	      请阅读
	      《用户须知》
	      </span>
            </div>
          </div>
        </div>

        <el-dialog title="用微信扫描下面二维码，完成实名认证" :visible.sync="setQrcodeShow" :before-close="qrCodeDivClose" top="100px" width="500px">
          <el-div style="display: flex; flex-direction: column; align-items: center;">

            <div class="center" style="display: inline;">
              <div class="qrcode-img">
                <div class="qrcode" v-if="qrcode != ''">
                    <img  :src="qrcode" width="287px" height="287px" />
                </div>
              </div>
            </div>
          </el-div>
        </el-dialog>

        <el-dialog title="用户须知" :visible.sync="guideDivShow" :before-close="guideDivClose" top="100px" width="500px">
          <div style="display: flex; flex-direction: column; align-items: center;">

            <div class="center" style="display: inline;border-top:1px solid #CCCCCC;">
              <div class="guide">
<p><b>隐私保护声明：</b></p>
<p>1、本站视频认证为了响应国家网络安全要求。网络交易必须实名制；</p>
<p>2、本站视频认证数据源来自百度AI数字。视频匹配与自行填写真实姓名身份证号匹配方可自动认证；</p>
<p>3、关于隐私保护：本站对用户填写的姓名、电话号、身份证号不对外开放，实行隐私保护；</p>
<p>4、关于隐私：在线视频认证时不保留任何视频数据，只匹配实名使用；</p>
<p>5、敬请用户放心使用！</p>
              </div>
            </div>
          </div>
        </el-dialog>

        <!-- --------------------------step3 已经认证 testarea -------------------------->
        <div class="qrcode-friends" id="isAuthDiv" style="display:none;">
          <div class="qrcode-title" style="border-bottom:1px solid #CCC;padding:0 0 10px 10px;">您已经完成实名认证</div>
          <div class="qrcode-link">
            <div class="user-line">
              真 实 姓 名：<input name="auth_true_name" id="auth_true_name" disabled></input>
            </div>
            <div class="user-line">
              身份证号码：<input name="auth_idnumber" id="auth_idnumber" disabled></input>
            </div>
          </div>
        </div>
      </template>
    </app-main>
  </div>
</template>
<script>
import AppMain from 'components/baseStructure/AppMain'
import { checkTruename, getAuthQrcode, setUserinfo } from 'network/api'
import { JSEncrypt } from 'jsencrypt'

export default {
  components: {
    AppMain
    // DataStatistics
  },
  data() {
    return {
      qrcode: '',
      setUserinfoShow: false,
      setQrcodeShow: false,
      guideDivShow: false,
      timer: null,
      mailHostUrl: 'http://api.daifa68.com'
    }
  },
  created() {
    // 初始化全部邀请信息
    this.checkTrueAuth()
    this.setQrcodeShow = false
  },
  methods: {
    checkTrueAuth(){
      checkTruename().then((res) => {
        if (res.code == 2) {
          // 已经认证
          this.setQrcodeShow = false
          document.getElementById('auth_true_name').value = res.data.true_name
          document.getElementById('auth_idnumber').value = res.data.idnumber
          document.getElementById('setUserDiv').style.display = 'none'
          document.getElementById('isAuthDiv').style.display = ''
        } else if (res.code == 1) {
          // 已经完善姓名身份证号
          this.setQrcodeShow = false
          document.getElementById('true_name').value = res.data.true_name
          document.getElementById('idnumber').value = res.data.idnumber
          document.getElementById('setUserDiv').style.display = ''
          document.getElementById('isAuthDiv').style.display = 'none'

          this.setAuthQrcode()
        } else {
          // 未认证
          this.setQrcodeShow = false
          document.getElementById('setUserDiv').style.display = ''
          document.getElementById('isAuthDiv').style.display = 'none'
        }
      })
    },
    /*
    // 实名认证
    realnameAuth() {
      getVerifyToken().then((res) => {
        if (res.success == 1) {
          var successUrl = this.mailHostUrl + '/api/User/authSuccessCallback'
          var failedUrl = this.mailHostUrl + '/api/User/authFaileCallback'
          var url = ' https://brain.baidu.com/face/print/?token='+res.result.verify_token
              url += '&successUrl='+encodeURIComponent(successUrl)
              url += '&failedUrl='+encodeURIComponent(failedUrl)
          // alert(url)
          // window.localtion.href=url
        }
      })
    },
    */
    // 设置个人资料
    postSubmit() {
      var true_name = document.getElementById('true_name').value
      var idnumber = document.getElementById('idnumber').value
      if(true_name =='')        this.$message.error('请填入真实姓名')
      if(idnumber == '')        this.$message.error('请填入身份证号码')

      var PUBLIC_KEY = "-----BEGIN PUBLIC KEY-----\n"+"MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAK4P9UIKxcnpl2OHhAPaPyI2nQiWhNk6 1MpT2RcVBPv3sN1B6x2b3XKAC3mzZOGh9Aj14LXfnjlEDwLZLxg39HECAwEAAQ=="+"-----END PUBLIC KEY-----\n";

      var encrypt = new JSEncrypt()
      encrypt.setPublicKey(PUBLIC_KEY)

      // true_name = encrypt.encrypt(true_name)
      // idnumber = encrypt.encrypt(idnumber)

      setUserinfo({
        true_name: true_name,
        idnumber: idnumber
      }).then((res) => {
        if (res.code !== 1) {
          this.$message.error('生产二维码失败')
        }
        this.checkTrueAuth()
      })
    },
    setAuthQrcode(){
      // 邀请图片
      getAuthQrcode().then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg)
        } else {
          this.qrcode = res.data
          this.setQrcodeShow = true
          document.getElementById('setUserDiv').style.display = ''
          document.getElementById('isAuthDiv').style.display = 'none'
          
          this.timer = window.setInterval(() => {
            setTimeout(this.changeState(), 0)
          }, 12000)
        }
      })
    },
    changeState(){
      checkTruename().then((res) => {
        if (res.code == 2) {
          // 已经认证
          this.setQrcodeShow = false
          document.getElementById('setUserDiv').style.display = 'none'
          document.getElementById('isAuthDiv').style.display = ''
          clearInterval(this.timer)
        }
      })
    },
    qrCodeDivClose(){
      this.setQrcodeShow = false
    },
    guideDialogShow(){
      this.guideDivShow = true
    },
    guideDivClose(){
      this.guideDivShow = false
    }

  }
}
function changeState(){
  alert('out state')
}
</script>
<style scoped src="styles/views/account/realnameAuth.css"></style>
<style scoped>
::v-deep .el-dialog__body{
  margin-top:10px;
  padding:0;
  background-color:#FFFFFF;
}

</style>